import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'hash',
    routes: [{
            path: '/',
            // component: () => import("@/layouts/Main.vue"),
            component: () => import("@/pages/Login.vue"),
            // redirect: '/dashboard'
        },
        {
            path: '/dashboard',
            meta: {
                isAuth: true
            },
            component: () => import("@/layouts/DashboardLayout.vue"),
            children: [

                {
                    path: '/',
                    component: () => import("@/pages/Dashboard.vue")
                }, {
                    path: 'manage_services',
                    name: 'services',
                    component: () => import('@/pages/Services.vue')
                },
                {
                    path: 'profile',
                    name: 'profile',
                    component: () => import('@/pages/Profile.vue')
                },
                {
                    path: 'manage_orders',
                    name: 'orders',
                    component: () => import('@/pages/Orders.vue')
                },



            ]
        },
        // {
        //  //   path: '/auth',
        //     component: () => import("@/pages/Login.vue"),
        // }
    ],

})
//nav guards
router.beforeEach((to, _from, next) => {
    var isAuth = to.matched.some(record => record.meta.isAuth)
    console.log(isAuth)
    var user = store.getters.getUser
    // debugger
    if (isAuth) {
        if (user) {
            next()
        } else {
            next('/')
            //next()
        }
    } else {
        next()
    }
})

export default router