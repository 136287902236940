import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase'
import Cookies from 'js-cookie'
import axios from 'axios'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        user: null,
        isFromLogin: false,
        categories: null
    },
    getters: {
        getUser: (s) => {
            return s.user
        },
        getIsFromLogin: (s) => {
            return s.isFromLogin
        },
        getCategories: s => {
            return s.categories
        }
    },
    mutations: {
        setIsFromLogin: (s, p) => {
            s.isFromLogin = p
        },
        setUser: (s, p) => {
            s.user = p;
        },
        updateProfile(s, p) {
            s.user[p.field] = p.value
        }
    },
    actions: {
        loginWithJWT(s, p) {
            return new Promise(async (resolve, reject) => {

                var jwt = p.token

                var config = {
                    method: 'post',
                    url: 'https://auth-lzlajbcasq-ue.a.run.app/getCustomToken',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: JSON.stringify({
                        idToken: jwt
                    })
                };

                var {
                    data
                } = await axios(config)

                if (data.status == "OK") {
                    firebase.auth().signInWithCustomToken(data.token).then((res) => {
                        resolve()
                    }).catch(err => {
                        reject(err)
                    })
                } else {
                    reject(data.err)
                }
            })
        },
        setUserFromDB(s, p) {
            return new Promise((resolve, reject) => {
                var uid = p.uid
                var role = p.role
                firebase.firestore().collection('users').doc(uid).get().then(res => {
                    var userData = res.data()
                    if (userData === null || userData.role != role) {
                        // signout if user have no data in the DB
                        s.dispatch('signout')
                        s.commit('setUser', null)
                        s.dispatch('setIdToken', false)
                        resolve()
                    } else {
                        // set the user obj otherwise
                        s.commit('setUser', userData)
                        s.dispatch('setIdToken', true)
                        resolve()
                    }
                })

            })
        },
        logout(s, p) {
            // logout 
        },
        clearUser(s, p) {
            return new Promise(async (resolve, reject) => {

                s.dispatch('signout')
                s.commit('setUser', null)
                await s.dispatch('setIdToken', false)
                resolve()
            })
        },
        async setIdToken(s, p) {
            if (p) {
                //set cookies 
                const idToken = await firebase.auth().currentUser.getIdToken()
                console.log(idToken);
                var dev = process.env.NODE_ENV == 'development'
                Cookies.set('token', idToken, {
                    domain: dev ? 'localhost' : 'happysewa.com'
                });
                return idToken
            } else { //unset cookies
                Cookies.remove('token', {
                    domain: dev ? 'localhost' : 'happysewa.com'
                });
            }
        },
        signout(s, p) {
            firebase.auth().signOut()
        },

        ////////mians

        loadCategories({
            commit
        }, p) {
            firebase.firestore().collection('categories').get().then((snap) => {
                var arr = []
                snap.forEach((cat) => {
                    arr.push(cat.data())
                })
                commit('setCategories', arr)
            })
        },
        subToOrders(s, p) {
            firebase.firestore().collection('orders').where('vendor_id', '==', firebase.auth().currentUser.uid).onSnapshot((snap) => {
                var arr = []
                snap.forEach((cat) => {
                    arr.push(cat.data())
                })
                p(arr)
            })
        },
        changeStatus(s, p) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('orders').doc(p.id).update({
                    status: 'delivered'
                }).then((snap) => {
                    resolve()
                }).catch(err => {
                    reject()
                })
            })
        },
        updateProfile(s, p) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(s.getters.getUser.uid).update({
                    name: p.name,
                    address: p.address
                }).then(() => {
                    s.commit('updateProfile', {
                        field: 'name',
                        value: p.name
                    })
                    resolve()
                }).catch(err => {
                    reject(err)
                })
            })
        },
        ////////services

        subscribeToServices(s, p) {
            firebase.firestore().collection('services').where('created_by', '==', firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid)).onSnapshot(snap => {
                var arr = []
                snap.forEach(s => {
                    arr.push(s.data())
                })
                p(arr)
            })
        },
        addService(s, p) {
            return new Promise((resolve, reject) => {
                var ref = firebase.firestore().collection('services').doc()
                var id = ref.id

                var ref = firebase.firestore().collection('services').doc()
                var id = ref.id
                p.created_at = new firebase.firestore.Timestamp.fromDate(new Date())
                p.created_by = firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid)
                console.log(s.rootGetters.getUser.username + "     " + s.rootGetters.getUser)
                p.vendor_name = s.rootGetters.getUser.username
                p.id = id
                p.featured = false
                p.images = p.images || []
                p.orders = 0
                p.category_ref = firebase.firestore().collection('categories').doc(p.category_ref)
                console.log('payload', p)
                firebase.firestore().collection('services').doc(id).set(p).then(() => {
                    resolve()
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        editService(s, p) {
            return new Promise((resolve, reject) => {

                p.updated_at = new firebase.firestore.Timestamp.fromDate(new Date())
                p.category_ref = firebase.firestore().collection('categories').doc(p.category_ref.id)

                firebase.firestore().collection('services').doc(p.id).update(
                    p
                ).then(() => {
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteService(s, p) {
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('services').doc(p.id).delete().then(() => {
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        }
    }
})
export default store;